import get from 'immutable-essentials/get';
import { getURLParam } from 'shared/lib/url';

window.locale = getLocale(LOCALES, navigator.languages);

const surveyPathRx = /^\/(?:survey|pesquisa|s|study|estudo|interview|entrevista)\/./;
const invitePathRx = /^\/(?:invite|convite|i)\/[0-9a-z]+\/(?:to|para|t)\/[0-9a-z]+(\/|$)/;
const electionPathRx = /^\/(?:election|eleição|eleicao)\/./;

if (electionPathRx.test(location.pathname)) {
  loadElection();
} else if (surveyPathRx.test(location.pathname)) {
  loadSurvey();
} else if (invitePathRx.test(location.pathname)) {
  loadSurvey({ withInvite: true });
} else {
  loadWebsite();
}

async function loadElection() {
  try {
    const pathname = decodeURI(location.pathname);
    const [, pathPrefix, surveyPath] = pathname.replace(/\/$/, '').split('/');
    const clientTime = Date.now();
    const res = await fetch(`${API_URL}/survey/path/${surveyPath}`);
    if (!res.ok) {
      throw res;
    }
    const { survey, time } = await res.json();
    const themeName = get(survey, ['theme', 'name'], get(survey, 'theme'));
    switch (themeName) {
    case 'Election': {
      const { browserThemeColor } = survey.theme;
      const themeColorMeta = document.createElement('meta');
      themeColorMeta.setAttribute('name', 'theme-color');
      themeColorMeta.setAttribute('content', browserThemeColor || '#171F17');
      document.head.appendChild(themeColorMeta);
      document.title = survey.title || survey.name;
      const themeApp = await import('./themes/Election/index.jsx');
      const basePath = `/${pathPrefix}${survey.publish.path}`;
      const token = sessionStorage.getItem(`${basePath}/token`) || '';
      let opinion = {};
      if (token) {
        try {
          const res = await fetch(`${API_URL}/token/survey/${survey.id}/opinion`, {
            headers: {
              Authorization: `Basic64 ${Buffer.from(token).toString('base64')}`,
            },
          });
          if (res.ok || res.status === 409) {
            opinion = await res.json();
          }
        } catch (err) {
          // ignore
        }
      }
      themeApp.loadApp({
        props: {
          basePath,
          opinion,
          survey,
          token,
          timeDelta: time - clientTime,
        },
        node: document.getElementById('root'),
      });
      break;
    }
    case 'VCE': {
      const themeColorMeta = document.createElement('meta');
      themeColorMeta.setAttribute('name', 'theme-color');
      themeColorMeta.setAttribute('content', '#202024');
      document.head.appendChild(themeColorMeta);
      document.title = survey.title || survey.name;
      const themeApp = await import('./themes/VCE/index.jsx');
      const basePath = `/${pathPrefix}${survey.publish.path}`;
      const token = sessionStorage.getItem(`${basePath}/token`) || '';
      let opinion = {};
      if (token) {
        try {
          const res = await fetch(`${API_URL}/token/survey/${survey.id}/opinion`, {
            headers: {
              Authorization: `Basic64 ${Buffer.from(token).toString('base64')}`,
            },
          });
          if (res.ok || res.status === 409) {
            opinion = await res.json();
          }
        } catch (err) {
          // ignore
        }
      }
      themeApp.loadApp({
        props: {
          basePath,
          opinion,
          survey,
          token,
          timeDelta: time - clientTime,
        },
        node: document.getElementById('root'),
      });
      break;
    }
    case 'VikingAssociationElection': {
      const themeColorMeta = document.createElement('meta');
      themeColorMeta.setAttribute('name', 'theme-color');
      themeColorMeta.setAttribute('content', '#171F17');
      document.head.appendChild(themeColorMeta);
      document.title = survey.title || survey.name;
      const themeApp = await import('./themes/VikingAssociationElection/index.jsx');
      const basePath = `/${pathPrefix}${survey.publish.path}`;
      const token = sessionStorage.getItem(`${basePath}/token`) || '';
      let opinion = {};
      if (token) {
        try {
          const res = await fetch(`${API_URL}/token/survey/${survey.id}/opinion`, {
            headers: {
              Authorization: `Basic64 ${Buffer.from(token).toString('base64')}`,
            },
          });
          if (res.ok || res.status === 409) {
            opinion = await res.json();
          }
        } catch (err) {
          // ignore
        }
      }
      themeApp.loadApp({
        props: {
          basePath,
          opinion,
          survey,
          token,
          timeDelta: time - clientTime,
        },
        node: document.getElementById('root'),
      });
      break;
    }
    // case 'VikingAssociationElectionLegacy': {
    //   const themeColorMeta = document.createElement('meta');
    //   themeColorMeta.setAttribute('name', 'theme-color');
    //   themeColorMeta.setAttribute('content', '#171F17');
    //   document.head.appendChild(themeColorMeta);
    //   document.title = survey.title || survey.name;
    //   const themeApp = await import('./themes/VikingAssociationElectionLegacy/index.jsx');
    //   const basePath = `/${pathPrefix}${survey.publish.path}`;
    //   const token = sessionStorage.getItem(`${basePath}/token`) || '';
    //   let opinion = {};
    //   if (token) {
    //     try {
    //       const res = await fetch(`${API_URL}/token/survey/${survey.id}/opinion`, {
    //         headers: {
    //           Authorization: `Basic64 ${Buffer.from(token).toString('base64')}`,
    //         },
    //       });
    //       if (res.ok || res.status === 409) {
    //         opinion = await res.json();
    //       }
    //     } catch (err) {
    //       // ignore
    //     }
    //   }
    //   themeApp.loadApp({
    //     props: {
    //       basePath,
    //       opinion,
    //       survey,
    //       token,
    //       timeDelta: time - clientTime,
    //     },
    //     node: document.getElementById('root'),
    //   });
    //   break;
    // }
    case 'VikingPrevElection': {
      const themeColorMeta = document.createElement('meta');
      themeColorMeta.setAttribute('name', 'theme-color');
      themeColorMeta.setAttribute('content', '#171F17');
      document.head.appendChild(themeColorMeta);
      document.title = survey.title || survey.name;
      const themeApp = await import('./themes/VikingPrevElection/index.jsx');
      const basePath = `/${pathPrefix}${survey.publish.path}`;
      const token = sessionStorage.getItem(`${basePath}/token`) || '';
      let opinion = {};
      if (token) {
        try {
          const res = await fetch(`${API_URL}/token/survey/${survey.id}/opinion`, {
            headers: {
              Authorization: `Basic64 ${Buffer.from(token).toString('base64')}`,
            },
          });
          if (res.ok || res.status === 409) {
            opinion = await res.json();
          }
        } catch (err) {
          // ignore
        }
      }
      themeApp.loadApp({
        props: {
          basePath,
          opinion,
          survey,
          token,
          timeDelta: time - clientTime,
        },
        node: document.getElementById('root'),
      });
      break;
    }
    default:
      throw new Error(`Theme "${survey.theme}" does not exist`);
    }
  } catch (err) {
    if (ENV !== 'PROD') {
      console.error(err);
    }
    loadWebsite();
  }
}

async function loadSurvey({ withInvite } = {}) {
  try {
    let res, basePath;
    if (withInvite) {
      const [,, inviteId,, toId ] = location.pathname.split('/');
      basePath = location.pathname.split('/').slice(0, 5).join('/');
      res = await fetch(`${API_URL}/invite/${inviteId}/to/${toId}/survey`);
    } else {
      const pathname = decodeURI(location.pathname).replace(/\/(?:done|feito|d|e|(?:block|bloco|b)\/[0-9]+\/(?:page|página|p)\/[0-9]+)$/, '');
      const [,, ...pathParts] = pathname.replace(/\/$/, '').split('/');
      basePath = location.pathname.split('/').slice(0, pathParts.length + 2).join('/');
      const owid = getURLParam('owid');
      const query = owid ? `?owid=${owid}` : '';
      res = await fetch(`${API_URL}/survey/path/${pathParts.join('/')}${query}`);
    }
    if (!res.ok) {
      throw res;
    }
    const { invite, isLive, opinion, survey, time } = await res.json();
    if (!isLive && survey.publish?.redirect) {
      const [, basePath] = location.pathname.split('/');
      const { publish: { redirect } } = survey;
      const redirectPath = redirect.startsWith('/') && !surveyPathRx.test(redirect) && !invitePathRx.test(redirect)
        ? `/${basePath}${redirect}`
        : redirect;
      location.replace(redirectPath);
      return;
    }
    const node = document.getElementById('root');
    const themeName = get(survey, ['theme', 'name'], get(survey, 'theme'));
    document.title = get(survey, 'title', get(survey, 'name'));
    history.replaceState(location.pathname, '', `${location.pathname}${location.search}`);
    switch (themeName) {
    // case 'CasaBauducco': {
    //   if (invite) {
    //     survey.invite = invite;
    //   }
    //   const themeColorMeta = document.createElement('meta');
    //   themeColorMeta.setAttribute('name', 'theme-color');
    //   themeColorMeta.setAttribute('content', '#271407');
    //   document.head.appendChild(themeColorMeta);
    //   (await import('./themes/CasaBauducco/index.jsx')).loadApp({
    //     props: {
    //       basePath,
    //       survey,
    //       done: get(opinion, 'done', false),
    //     },
    //     node,
    //   });
    //   break;
    // }
    // case 'CleanBlue':
    //   if (invite) {
    //     survey.invite = invite;
    //   }
    //   (await import('./themes/CleanBlue/index.jsx')).loadApp({
    //     props: {
    //       basePath,
    //       survey,
    //       done: get(opinion, 'done', false),
    //     },
    //     node,
    //   });
    //   break;
    // case 'ChatBot': {
    //   const { browserThemeColor } = survey.theme;
    //   const themeColorMeta = document.createElement('meta');
    //   themeColorMeta.setAttribute('name', 'theme-color');
    //   themeColorMeta.setAttribute('content', browserThemeColor || '#171F17');
    //   document.head.appendChild(themeColorMeta);
    //   (await import('./themes/ChatBot/index.jsx')).loadApp({
    //     props: {
    //       basePath,
    //       invite,
    //       survey,
    //       opinion,
    //       time,
    //     },
    //     node,
    //   });
    //   break;
    // }
    // case 'BlueGreen':
    //   if (invite) {
    //     survey.invite = invite;
    //   }
    //   (await import('./themes/BlueGreen/index.jsx')).loadApp({
    //     props: {
    //       basePath,
    //       survey,
    //       done: get(opinion, 'done', false),
    //     },
    //     node,
    //   });
    //   break;
    // case 'UnimedPontaGrossa': {
    //   if (invite) {
    //     survey.invite = invite;
    //   }
    //   const themeColorMeta = document.createElement('meta');
    //   themeColorMeta.setAttribute('name', 'theme-color');
    //   themeColorMeta.setAttribute('content', '#171F17');
    //   document.head.appendChild(themeColorMeta);
    //   (await import('./themes/UnimedPontaGrossa/index.jsx')).loadApp({
    //     props: {
    //       basePath,
    //       survey,
    //       done: get(opinion, 'done', false),
    //     },
    //     node,
    //   });
    //   break;
    // }
    // case 'UnimedChapeco': {
    //   if (invite) {
    //     survey.invite = invite;
    //   }
    //   const themeColorMeta = document.createElement('meta');
    //   themeColorMeta.setAttribute('name', 'theme-color');
    //   themeColorMeta.setAttribute('content', '#171F17');
    //   document.head.appendChild(themeColorMeta);
    //   (await import('./themes/UnimedChapeco/index.jsx')).loadApp({
    //     props: {
    //       basePath,
    //       survey,
    //       done: get(opinion, 'done', false),
    //     },
    //     node,
    //   });
    //   break;
    // }
    case 'Unimed': {
      if (invite) {
        survey.invite = invite;
      }
      const themeColorMeta = document.createElement('meta');
      themeColorMeta.setAttribute('name', 'theme-color');
      themeColorMeta.setAttribute('content', '#171F17');
      document.head.appendChild(themeColorMeta);
      (await import('./themes/Unimed/index.jsx')).loadApp({
        props: {
          basePath,
          survey,
          done: get(opinion, 'done', false),
        },
        node,
      });
      break;
    }
    // case 'Brand': {
    //   if (invite) {
    //     survey.invite = invite;
    //   }
    //   const { browserThemeColor } = survey.theme;
    //   const themeColorMeta = document.createElement('meta');
    //   themeColorMeta.setAttribute('name', 'theme-color');
    //   themeColorMeta.setAttribute('content', browserThemeColor || '#171F17');
    //   document.head.appendChild(themeColorMeta);
    //   (await import('./themes/Brand/index.jsx')).loadApp({
    //     props: {
    //       basePath,
    //       survey,
    //       done: get(opinion, 'done', false),
    //     },
    //     node,
    //   });
    //   break;
    // }
    // case 'NoBrand': {
    //   if (invite) {
    //     survey.invite = invite;
    //   }
    //   const themeColorMeta = document.createElement('meta');
    //   themeColorMeta.setAttribute('name', 'theme-color');
    //   themeColorMeta.setAttribute('content', '#171F17');
    //   document.head.appendChild(themeColorMeta);
    //   (await import('./themes/NoBrand/index.jsx')).loadApp({
    //     props: {
    //       basePath,
    //       survey,
    //       done: get(opinion, 'done', false),
    //     },
    //     node,
    //   });
    //   break;
    // }
    // case 'NoBrandOfferWise': {
    //   if (invite) {
    //     survey.invite = invite;
    //   }
    //   const themeColorMeta = document.createElement('meta');
    //   themeColorMeta.setAttribute('name', 'theme-color');
    //   themeColorMeta.setAttribute('content', '#171F17');
    //   document.head.appendChild(themeColorMeta);
    //   (await import('./themes/NoBrandOfferWise/index.jsx')).loadApp({
    //     props: {
    //       basePath,
    //       survey,
    //       done: get(opinion, 'done', false),
    //     },
    //     node,
    //   });
    //   break;
    // }
    // case 'HagaBuns': {
    //   if (invite) {
    //     survey.invite = invite;
    //   }
    //   const themeColorMeta = document.createElement('meta');
    //   themeColorMeta.setAttribute('name', 'theme-color');
    //   themeColorMeta.setAttribute('content', '#171F17');
    //   document.head.appendChild(themeColorMeta);
    //   (await import('./themes/HagaBuns/index.jsx')).loadApp({
    //     props: {
    //       basePath,
    //       survey,
    //       done: get(opinion, 'done', false),
    //     },
    //     node,
    //   });
    //   break;
    // }
    case 'Themex': {
      const { browserThemeColor } = survey.theme;
      const themeColorMeta = document.createElement('meta');
      themeColorMeta.setAttribute('name', 'theme-color');
      themeColorMeta.setAttribute('content', browserThemeColor || '#171F17');
      document.head.appendChild(themeColorMeta);
      (await import('./themes/Themex/index.jsx')).loadApp({
        props: {
          basePath,
          invite,
          survey,
          opinion,
          time,
        },
        node,
      });
      break;
    }
    case 'ThemexToken': {
      const { browserThemeColor } = survey.theme;
      const themeColorMeta = document.createElement('meta');
      themeColorMeta.setAttribute('name', 'theme-color');
      themeColorMeta.setAttribute('content', browserThemeColor || '#171F17');
      document.head.appendChild(themeColorMeta);
      (await import('./themes/ThemexToken/index.jsx')).loadApp({
        props: {
          basePath,
          invite,
          survey,
          opinion,
          time,
        },
        node,
      });
      break;
    }
    case 'Shell': {
      const { browserThemeColor } = survey.theme;
      const themeColorMeta = document.createElement('meta');
      themeColorMeta.setAttribute('name', 'theme-color');
      themeColorMeta.setAttribute('content', browserThemeColor || '#171F17');
      document.head.appendChild(themeColorMeta);
      (await import('./themes/Shell/index.jsx')).loadApp({
        props: {
          basePath,
          invite,
          survey,
          opinion,
          time,
        },
        node,
      });
      break;
    }
    default:
      throw new Error(`Theme "${survey.theme}" does not exist`);
    }
  } catch (err) {
    if (ENV !== 'PROD') {
      throw err;
    }
    loadWebsite();
  }
}

async function loadWebsite() {
  if (ENV === 'PROD' && VAR === 'ZX') {
    location.replace('https://zoompesquisas.com.br/#plataformazx');
  } else {
    history.replaceState('/', '', '/');

    const node = document.getElementById('root');
    (await import('./index.jsx')).loadApp({ node });
  }
}

function getLocale(supportedLocales, userLocales) {
  for (const lang of userLocales) {
    for (const locale of supportedLocales) {
      if (lang === locale || lang.split('-')[0] === locale || lang === locale.split('-')[0]) {
        return locale;
      }
    }
  }
  for (const lang of userLocales) {
    for (const locale of supportedLocales) {
      if (lang.split('-')[0] === locale.split('-')[0]) {
        return locale;
      }
    }
  }
  return supportedLocales[0];
}
